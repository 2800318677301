import { Permission } from '../tenant_portal/ContextProvider';
import { CountryContextType } from './AddressContainer';
import { OauthProvider, permissionsPageNames } from './Constants';
import { CustomFieldContent, ErrorType } from './Types';
import GoogleLogoPath from '../../../images/login/google.svg';
import AppleLogoPath from '../../../images/login/apple.svg';
import FacebookLogoPath from '../../../images/login/facebook.svg';
import MicrosoftLogoPath from '../../../images/login/microsoft.svg';
import EmailLogoPath from '../../../images/login/email.svg';
import { graphql } from 'relay-runtime';
import { useMutation } from 'react-relay';

export const formatCurrencyString = (
  number: number | string,
  includeDecimals = true
): string => {
  if (typeof number === 'string') number = parseFloat(number);
  const currency = number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: includeDecimals ? 2 : 0,
  });
  if (currency === '-$0') return '$0';
  return currency;
};

export const formatUIDateString = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero based
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
};

export const formatUIDatetimeString = (datetimeString: string): string => {
  const date = new Date(datetimeString);

  const dateFormatter = new Intl.DateTimeFormat();
  const formattedDate = dateFormatter.format(date);

  const timeFormatter = new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
  const formattedTime = timeFormatter.format(date);

  return `${formattedDate} ${formattedTime}`;
};

export const formatCreditCardExpDate = (dateString: string): string => {
  const date = new Date(dateString);
  const month = date.getUTCMonth() + 1; // Months are zero based
  const year = date.getUTCFullYear().toString().slice(-2);

  return `${month}/${year}`;
};

export const getNextAutoPaymentDate = (
  day: number = new Date().getDate()
): string => {
  const today = new Date();
  const nextInstance = new Date();

  nextInstance.setMonth(today.getMonth(), day);

  if (nextInstance.getDate() == day) {
    if (nextInstance <= today) {
      nextInstance.setMonth(today.getMonth() + 1, day);
    }
  } else {
    nextInstance.setMonth(today.getMonth() + 1, 0);

    if (nextInstance < today) {
      nextInstance.setMonth(today.getMonth() + 2, 0);
    }
  }
  return nextInstance.toLocaleDateString();
};

export const firstAllowedPage = (leasePermissions: Permission) => {
  if (leasePermissions[permissionsPageNames.PAYMENTS]) {
    return 'payments';
  } else if (leasePermissions[permissionsPageNames.MAINTENANCE]) {
    return 'maintenance';
  } else if (leasePermissions[permissionsPageNames.DOCUMENTS]) {
    return 'documents';
  } else if (leasePermissions[permissionsPageNames.CONTACT]) {
    return 'contact';
  } else {
    return 'account';
  }
};

export const replaceZipWithPostal = (
  label: string,
  country: CountryContextType['country']
): string => {
  if (country === 'USA') {
    return label;
  } else {
    return label
      .replace('Zip Code', 'Postal Code')
      .replace('Zip', 'Postal Code');
  }
};

export const formatErrorMessages = (errors: ErrorType[], label: string) => {
  const formattedMessages = errors
    .map((error) => {
      return error.messages.map((message) => {
        return `${label !== '' ? `${label} ${message}.` : message} `;
      });
    })
    .flat()
    .join(' ');
  return formattedMessages;
};

export const toCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/\s+/g, ' ')
    .trim()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (/\s+/.test(match)) return '';
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

export const camelCaseToTitle = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b[a-z]/g, (char) => char.toUpperCase());
};

export const getSystemTheme = () => {
  let theme = 'standard';
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    theme = 'dark';
  }
  return theme;
};

export const formatProviderName = (provider: string) => {
  const providerName = Object.entries(OauthProvider).find(([key, value]) => {
    return value === provider;
  })?.[0];
  if (!providerName) return provider;
  return (
    providerName.charAt(0).toUpperCase() + providerName.slice(1).toLowerCase()
  );
};

export const getProviderLogoPath = (provider: string) => {
  let path = '';
  switch (provider) {
    case OauthProvider.GOOGLE:
      path = GoogleLogoPath;
      break;
    case OauthProvider.APPLE:
      path = AppleLogoPath;
      break;
    case OauthProvider.FACEBOOK:
      path = FacebookLogoPath;
      break;
    case OauthProvider.MICROSOFT:
      path = MicrosoftLogoPath;
      break;
    case OauthProvider.EMAIL:
      path = EmailLogoPath;
      break;
  }
  return path;
};

const SendErrorReport = graphql`
  mutation ToolsSendErrorReportMutation($input: SendErrorReportInput!) {
    sendErrorReport(input: $input) {
      errors {
        path
        messages
      }
    }
  }
`;

export function useSendErrorReport() {
  const [sendErrorReport] = useMutation(SendErrorReport);

  const submitErrorReport = (subject: string, body: string) => {
    sendErrorReport({
      variables: {
        input: {
          errorReportInfo: {
            subject: subject,
            body: body,
          },
        },
      },
    });
  };

  return submitErrorReport;
}

export function getNetworkErrorMessage(
  actionError: string,
  networkError: Error
) {
  if (networkError.message.includes('Received status code 500'))
    actionError +=
      '. Please contact Property Matrix support for assistance (Code 500).';
  else actionError += `: ${networkError.message}`;
  return actionError;
}

export function getCustomFieldValue(content: CustomFieldContent) {
  return (
    content.checkboxValue ||
    content.currencyValue ||
    content.dateValue ||
    content.dateTimeValue ||
    content.memoValue ||
    content.numberValue ||
    content.selectValue ||
    content.textValue ||
    content.timeValue
  );
}

export function getRailsIdFromRelayId(relayId: string) {
  return atob(relayId).split('/').pop();
}
export function getRelayIdFromRailsId(modelName: string, railsId: string) {
  return btoa(`gid://property-matrix/${modelName}/${railsId}`).replace(
    /=+$/,
    ''
  );
}

export function rechartsCurrencyFormatter(value: string) {
  const number = parseFloat(value);
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

function hexToRgb(hex: string) {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return [r, g, b];
}

function rgbToHex(r: number, g: number, b: number) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function lightenColor(color: string, percent: number) {
  const [r, g, b] = hexToRgb(color);
  const newR = Math.min(255, Math.floor(r + (255 - r) * percent));
  const newG = Math.min(255, Math.floor(g + (255 - g) * percent));
  const newB = Math.min(255, Math.floor(b + (255 - b) * percent));
  return rgbToHex(newR, newG, newB);
}

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export function getWidgetDimensionLimits(widgetType: string) {
  let minWidth = 2;
  let minHeight = 2;
  let maxWidth = 4;
  let maxHeight = 4;
  switch (widgetType) {
    case 'DashboardWidgetsIncomeVsExpense':
      minWidth = 5;
      minHeight = 2;
      maxWidth = 12;
      maxHeight = 4;
      break;
    case 'DashboardWidgetsLeadsByLabel':
      minWidth = 3;
      minHeight = 2;
      maxWidth = 6;
      maxHeight = 4;
      break;
    case 'DashboardWidgetsTotalRevenue':
      minWidth = 3;
      minHeight = 1;
      maxWidth = 6;
      maxHeight = 4;
      break;
    case 'DashboardWidgetsTotalOutstandingBalance':
      minWidth = 2;
      minHeight = 1;
      maxWidth = 6;
      maxHeight = 4;
      break;
    case 'DashboardWidgetsIncomeByProperty':
      minWidth = 3;
      minHeight = 2;
      maxWidth = 6;
      maxHeight = 4;
      break;
    case 'DashboardWidgetsUpcomingLeaseExpiration':
      minWidth = 3;
      minHeight = 2;
      maxWidth = 6;
      maxHeight = 4;
      break;
    case 'DashboardWidgetsOccupancyRate':
      minWidth = 3;
      minHeight = 1;
      maxWidth = 6;
      maxHeight = 4;
      break;
    case 'DashboardWidgetsExpenseByProperty':
      minWidth = 3;
      minHeight = 2;
      maxWidth = 6;
      maxHeight = 4;
      break;
    case 'DashboardWidgetsOutstandingBalances':
      minWidth = 5;
      minHeight = 2;
      maxWidth = 12;
      maxHeight = 4;
      break;
    case 'DashboardWidgetsMaintenanceRequestsByCategoryAndStatus':
      minWidth = 3;
      minHeight = 2;
      maxWidth = 6;
      maxHeight = 4;
      break;
  }
  return {
    minWidth: minWidth,
    minHeight: minHeight,
    maxWidth: maxWidth,
    maxHeight: maxHeight,
  };
}
